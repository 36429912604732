<template>
  <div>
    <agent-tab select_page="report_winlost" @prefix="onPrefix" />
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายงานบัญชีแพ้ชนะ
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row>
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-6">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1 mb-1"> ค้นหา </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-success waves-effect waves-light mr-1 mb-1"> วันนี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-info waves-effect waves-light mr-1 mb-1"> เมื่อวาน </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-secondary waves-effect waves-light mr-1 mb-1"> สัปดาห์นี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-danger waves-effect waves-light mr-1 mb-1"> สัปดาห์ที่แล้ว </b-button>
              <b-button @click="this.filter=''" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light mr-1 mb-1"> ย้อนกลับ </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1">
              <b-row>
                <div class="btn-group btn-group-sm mx-auto" role="group">
                  <b-button variant="outline-secondary" class="btn" disabled>คืนยอดเสีย: </b-button>
                  <b-form-input id="percentInput" v-model="percentInput" placeholder="เปอร์เซ็น" type="number" style="border-radius: 0;height:auto;width:75px"/>
                  <b-form-input id="lessInput" v-model="lessInput" placeholder="คืนขั้นต่ำ" type="number" style="border-radius: 0;height:auto;width:75px"/>
                  <b-button variant="btn-primary" class="btn bg-warning text-white" @click="result()">คำนวน</b-button>
                  <b-button variant="btn-primary" class="btn bg-success text-white" @click="save()">บันทึก Excel</b-button>
                </div>
              </b-row>
            </b-col>
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items.slice(start, start+perPage)" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(user)="data">
                  <div class="text-primary">{{ data.value }}</div>
                </template>
                <template #cell(winlost)="data">
                  <div :class="{'text-danger': data.value.includes('-')}">{{ data.value }}</div>
                </template>
                <template #cell(winlostcomm)="data">
                  <div :class="{'text-danger': data.value.includes('-')}">{{ data.value }}</div>
                </template>
                <template #cell(refund)="data">
                  <div class="text-warning">{{ data.value || 0 }}</div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AgentTab from './agent_tab.vue'
import func from '@core/comp-functions/misc/func'
import XLSX from '@/assets/plugins/xlsx/xlsx.full.min.js'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
    AgentTab,
  },
  data() {
    return {
      func: func,
      PATH: '/agent',
      BASE_URL: process.env.BASE_URL,
      prefix: '',
      date_start_report: '',
      date_end_report: '',
      start: 0,
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100, 500, 1000, 5000, 10000],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      percentInput: null,
      lessInput: null,
      excel_data_refund: [],
      excel_data_refund_save: [],
      sorted: '',
      filter: '',
      filterOn: [],
      fields: [
        { key: 'user', label: 'บัญชี', class: 'text-center' },
        { key: 'name', label: 'ชื่อ - นามสกุล', class: 'text-center' },
        { key: 'sum_amount', label: 'จำนวน', class: 'text-right' },
        { key: 'amount', label: 'จำนวนถูกต้อง', class: 'text-right' },
        { key: 'count', label: 'การนับเดิมพัน', class: 'text-right' },
        { key: 'sum_comm', label: 'ยอดรวมของคอมมิชชั่น', class: 'text-right' },
        { key: 'winlost', label: 'ชนะ/แพ้', class: 'text-right' },
        { key: 'comm', label: 'คอมมิชชั่น', class: 'text-right' },
        { key: 'winlostcomm', label: 'ชนะ/แพ้ + คอมมิชชั่น', class: 'text-right' },
      ],
      items: [],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
      excel_config: {
        columns: [
          {
            label: "Username",
            field: "user",
          },
          {
            label: "ชื่อ-สกุล",
            field: "name"
          },
          {
            label: "จำนวน",
            field: "sum_amount",
          },
          {
            label: "จำนวนถูกต้อง",
            field: "amount",
          },
          {
            label: "การนับเดิมพัน",
            field: "count",
          },
          {
            label: "ยอดรวมของคอมมิชชั่น",
            field: "sum_comm",
          },
          {
            label: "ชนะ/แพ้",
            field: "winlost",
          },
          {
            label: "คอมมิชชั่น",
            field: "comm",
          },{
            label: "ชนะ/แพ้ + คอมมิชชั่น",
            field: "winlostcomm",
          },
        ],
        columns2: [
          {
            label: "user",
            field: "user",
          },
          {
            label: "credit",
            field: "credit",
          },
        ],
        data: [],
        filename: 'excel',
        sheetname: 'รายการแพ้ชนะ'
      },
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.wait()
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onPrefix(prefix) {
      this.prefix = prefix
    },
    onFiltered(filteredItems) {
      if (this.filter != '' && this.filter.startsWith('type:')) {
        this.filter = ''
      }
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      this.start = this.perPage * (this.currentPage - 1)
      // this.fetch(start)
    },
    onPageChange(page) {
      this.start = this.perPage * (page - 1)
      // this.fetch(start)
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
      this.excel_data_refund = this.items
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    wait() {
      if (this.prefix == '') return setTimeout(()=>this.wait(), 1000/60)
      setTimeout(()=>this.fetch(), 1000/60)
    },
    fetch(start=0) {
      this.loading()
      let url = `agent/agent_winlost/${this.prefix}?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.sorted != '' ? this.sorted : this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data); this.$swal.close() }).catch(()=>this.$swal.close())
    },
    result() {
      if (this.percentInput == null || this.percentInput <= 0) {
        return this.warn('กรุณาระบุเปอร์เซ็น')
      }
      if (this.lessInput == null || this.lessInput < 0) {
        return this.warn('กรุณาระบุจำนวนคืนเงินขั้นต่ำ')
      }
      for (let i in this.items) {
        delete this.items[i].refund
      }
      this.excel_data_refund_save = [];
      for (let c of this.excel_data_refund) {
        if (c.user == null) continue;
        if (!c.user.match(/\d+/)) continue;
        if (!c.winlostcomm.includes('-')) continue;
        let credit = Math.floor((this.percentInput/100) * parseFloat(Number(c.winlostcomm.replace(/,/gi,''))));
        if (Math.abs(credit) < Number(this.lessInput)) continue;
        this.excel_data_refund_save.push({
          user: c.user,
          credit: Math.abs(credit)
        })
        const index = this.items.findIndex(object => {
          return object.user === c.user;
        });
        this.items[index].refund = Math.abs(credit);
      }
      if (!this.fields.find(a=>a.key=='refund'))
        this.fields.push({ key: 'refund', label: 'คืนยอดเสีย', class: 'text-right' });
      this.$forceUpdate();
      this.success('คำนวนคืนยอดเสียสำเร็จ กรุณาบันทึกไฟล์ Excel');
    },
    save() {
      let excel_config = this.excel_config;
      excel_config.data = this.excel_data_refund_save;
      excel_config.filename = "คืนยอดเสีย " + this.prefix;
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      if (excel_config.columns2.length === 0){
        console.log("Add columns!");
        return;
      }
      if (excel_config.data.length === 0){
        console.log("Add data!");
        return;
      }
      $.each(excel_config.columns2, function(index, value) {
        newXlsHeader.push(value.label);
      });
      createXLSLFormatObj.push(newXlsHeader);
      $.each(excel_config.data, function(index, value) {
        let innerRowData = [];
        $.each(excel_config.columns2, function(index, val) {
          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(value[val.field]));
          }else {
            innerRowData.push(value[val.field]);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });
      let filename = excel_config.filename + ".xlsx";
      let ws_name = excel_config.sheetname;
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
